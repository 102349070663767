import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  {
    path: 'qr/:eventId/:guestId/:salt',
    loadChildren: () => import('./qr/qr.module').then(m => m.QrModule)
  },
  {path: 'checkin/:eventId', loadChildren: () => import('./checkin/checkin.module').then(m => m.CheckinModule)},
  {path: '**', redirectTo: ''},
  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
