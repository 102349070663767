import { Component } from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
  selector: 'nbd-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  constructor() {
    location.href = 'https://realguest.it';
  }

}
